import { object, string } from 'prop-types'

import { bgColor, InfosWrapper } from './base.styles'
import { Description } from './components/Description'
import { Form } from './components/Form'
import { Image } from './components/Image'
import { Title } from './components/Title'
import { Wrapper } from './components/Wrapper'

const propTypes = {
  backgroundColor: string,
  description: string,
  form: object,
  imageAlign: string,
  imageDesktop: object,
  imageMobile: object,
  title: string,
}

const defaultProps = {
  imageAlign: 'left',
  backgroundColor: 'LIGHT',
}

const HeroHighlightImageWithForm = props => {
  const {
    backgroundColor,
    description,
    form,
    imageAlign,
    imageDesktop,
    imageMobile,
    title,
    ...others
  } = props

  const alignment = imageAlign || defaultProps.imageAlign
  const theme = backgroundColor?.toUpperCase() || 'LIGHT'
  const colorTheme = bgColor[theme] || {}

  return (
    <Wrapper themeColor={colorTheme.bg} align={alignment} {...others}>
      <InfosWrapper>
        <Title color={colorTheme.titleColor}>{title}</Title>
        <Description color={colorTheme.descriptionColor}>
          {description}
        </Description>
      </InfosWrapper>
      <Form form={form} />
      <Image
        imageDesktop={imageDesktop}
        imageMobile={imageMobile}
        alt={description || ''}
      />
    </Wrapper>
  )
}

HeroHighlightImageWithForm.propTypes = propTypes
HeroHighlightImageWithForm.defaultProps = defaultProps

export default HeroHighlightImageWithForm
