import { styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

const FormContainer = styled.div`
  grid-area: form;
  margin-bottom: ${selectTheme('spacingBottom.8xl')};

  ${media.up('2xl')} {
    margin-bottom: 0;
  }
`

export { FormContainer }
