import { css, styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import { Container as CreditasContainer } from 'components/Container'
import { Grid as GridComponent } from 'components/Grid'

const Section = styled.section(
  ({ theme, themeColor }) => css`
    background-color: ${selectTheme(`colors.${themeColor}`)({ theme })};
  `,
)

const Container = styled(CreditasContainer)`
  padding-top: ${selectTheme('spacingTop.6xl')};
  padding-bottom: ${selectTheme('spacingBottom.16xl')};

  ${media.up('5xl')} {
    padding-top: ${selectTheme('spacingTop.21xl')};
    padding-bottom: ${selectTheme('spacingBottom.21xl')};
  }
`

const gridTemplates = {
  right: {
    default: `
      '${'infos '.repeat(4)}'
      '${'form '.repeat(4)}'
      '${'image '.repeat(4)}'
    `,
    '2xl': `
      '${'infos '.repeat(4)} ${'image '.repeat(4)}'
      '${'form '.repeat(4)} ${'image '.repeat(4)}'
    `,
    '5xl': `
      '. ${'infos '.repeat(5)} . ${'image '.repeat(5)}'
      '. ${'form '.repeat(4)} . . ${'image '.repeat(5)}'
    `,
  },
  left: {
    default: `
      '${'infos '.repeat(4)}'
      '${'form '.repeat(4)}'
      '${'image '.repeat(4)}'
    `,
    '2xl': `
      '${'image '.repeat(4)} ${'infos '.repeat(4)}'
      '${'image '.repeat(4)} ${'form '.repeat(4)}'
    `,
    '5xl': `
      '${'image '.repeat(5)} . ${'infos '.repeat(5)} .'
      '${'image '.repeat(5)} . ${'form '.repeat(4)} . .'
    `,
  },
}

const Grid = styled(GridComponent)(
  ({ align }) => css`
    grid-template-areas: ${gridTemplates[align].default};

    ${media.up('2xl')} {
      grid-template-areas: ${gridTemplates[align]['2xl']};
      grid-template-rows: auto 1fr;
    }

    ${media.up('5xl')} {
      grid-template-areas: ${gridTemplates[align]['5xl']};
    }
  `,
)

export { Grid, Section, Container }
