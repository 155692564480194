import React from 'react'
import { string } from 'prop-types'

import { Typography } from './styles'

const propTypes = {
  children: string,
  color: string,
}

const defaultProps = {
  color: 'neutral.80',
}

export const Description = ({ children, color, ...others }) => {
  if (!children) return null

  return (
    <Typography
      color={color}
      variant={{
        _: 'bodyMdLight',
        '5xl': 'bodyXlLight',
      }}
      {...others}
    >
      {children}
    </Typography>
  )
}

Description.propTypes = propTypes
Description.defaultProps = defaultProps
