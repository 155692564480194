import { node, string } from 'prop-types'

import { Container, Grid, Section } from './styles'

const propTypes = {
  align: string,
  children: node,
  themeColor: string,
}

export const Wrapper = ({ align, children, themeColor, ...others }) => {
  return (
    <Section themeColor={themeColor} {...others}>
      <Container>
        <Grid align={align}>{children}</Grid>
      </Container>
    </Section>
  )
}

Wrapper.propTypes = propTypes
