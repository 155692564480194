import { styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

export const InfosWrapper = styled.div`
  grid-area: infos;

  ${media.up('2xl')} {
    margin-bottom: 0;
  }

  ${media.up('5xl')} {
    margin-top: ${selectTheme('spacingTop.14xl')};
  }
`

export const bgColor = {
  DARK: {
    bg: 'neutral.90',
    titleColor: 'neutral.0',
    descriptionColor: 'neutral.0',
  },
  LIGHT: {
    bg: 'neutral.0',
    titleColor: 'neutral.90',
    descriptionColor: 'neutral.80',
  },
}
