import { object } from 'prop-types'

import { breakpoints, Figure, ImageWrapper, LazyImage } from './styles'

const propTypes = {
  imageDesktop: object,
  imageMobile: object,
}

const artDirectionConfigs = {
  desktop: {
    sizes: '(max-width: 900px) 100vw, 900px',
    media: `(min-width: ${breakpoints['2xl']})`,
  },
  mobile: {
    sizes: '(max-width: 441px) 100vw, 441px',
  },
}

export const Image = ({ imageDesktop, imageMobile, ...others }) => {
  if (!imageDesktop && !imageMobile) return null

  const artDirection = [
    { ...artDirectionConfigs.desktop, ...imageDesktop?.fluid },
    { ...artDirectionConfigs.mobile, ...imageMobile?.fluid },
  ]

  return (
    <ImageWrapper>
      <Figure>
        <LazyImage artDirection={artDirection} {...others} />
      </Figure>
    </ImageWrapper>
  )
}

Image.propTypes = propTypes
