import { styled } from '@creditas-ui/system'
import { standard } from '@creditas-ui/themes'
import { media, selectTheme } from '@creditas-ui/utilities'

import { LazyImage as LazyImageComponent } from 'components/LazyImage'

const LazyImage = styled(LazyImageComponent)`
  box-shadow: ${selectTheme('shadow.sm')};
  display: block;
  height: auto;
  width: 100%;
  z-index: 1;
`

const Figure = styled.div`
  position: relative;

  &::after {
    bottom: 0;
    box-shadow: ${selectTheme('shadow.md')};
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    transform: translateX(5%);
    width: 90%;
  }
`

const ImageWrapper = styled.div`
  grid-area: image;
  margin-left: -${selectTheme('spacingLeft.5xl')};
  margin-right: -${selectTheme('spacingLeft.5xl')};

  ${media.up('2xl')} {
    margin-left: 0;
    margin-right: 0;
  }
`

const { breakpoints } = standard

export { LazyImage, breakpoints, Figure, ImageWrapper }
