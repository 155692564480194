import { object } from 'prop-types'

import HubspotForm from 'components/HubspotForm/HubspotForm'

import { FormContainer } from './styles'

const propTypes = {
  form: object.isRequired,
}

export const Form = ({ form }) => {
  if (!form) return null

  return (
    <FormContainer>
      <HubspotForm
        {...form}
        isGreenStone
        dispatchEvents
        formWithWebCardWrapper={false}
        template="Template03"
      />
    </FormContainer>
  )
}

Form.propTypes = propTypes
